(function($) {
    $.widget( "ogn.video", {
        _create: function() {
            this.defaultTime = 33;
            this.duration = this.defaultTime;
            this.isFirstVisit = this._getCookieValue();
            this.$container = $('.container', this.element);
            this.video = $('video', this.element).get(0);
            if (this.video) {
                this._createControl()
                if (this.isFirstVisit) {
                    this.video.play();
                } else {
                    this.video.poster = '/images/video-poster-home.jpg';
                    this.$container.addClass('content-shown');
                    this.$replay.show();
                    this.$skip.hide();
                }
            }
        },
        _init: function() {
            this.$replay.on('click', this._onPressReplay.bind(this));
            this.$skip.on('click', this._onPressSkip.bind(this));
            $(this.video).on('loadedmetadata', this._onLoadedData.bind(this));
            $(this.video).on('ended', this._onEnded.bind(this));
            $(this.video).on('timeupdate', this._onTimeUpdate.bind(this));
            $(this.video).on('playing', this._onPlay.bind(this));
            $(this.video).on('seeked', this._onSeeked.bind(this));

        },
        _createControl: function() {
            var $control = $('<div>').addClass('video-control').appendTo(this.$container);
            this.$replay = $('<button>')
                .attr('type', 'button')
                .addClass('btn btn-replay')
                .append($('<span>').html('Replay'))
                .append($('<i>').addClass('icon icon-player-replay'))
                .appendTo($control).hide();
            this.$skip = $('<button>')
                .attr('type', 'button')
                .addClass('btn btn-skip')
                .append($('<span>').html('Skip'))
                .append($('<i>').addClass('icon font-weight-bold icon-angle-double-right'))
                .appendTo($control);
        },
        _getCookieValue: function() {
            var key = 'affinity_first_visit';
            var isFirstVisit = !Cookie.get(key);
            var expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + (20 * 365));
            if (isFirstVisit) {
                Cookie.set(key, 'true', { expired: expireDate, path: '/'});
            }
            return isFirstVisit;
        },
        _onSeeked: function(event) {
            if (Math.floor(event.currentTarget.currentTime) === 0 || !event.currentTarget.paused) {
                this.$container.removeClass('content-shown');
                this.$replay.hide();
                this.$skip.show();
            } else if(Math.floor(event.currentTarget.currentTime) === this.duration || event.currentTarget.paused) {
                this.$container.addClass('content-shown');
                this.$replay.show();
                this.$skip.hide();
            }
        },
        _onLoadedData: function() {
            this.duration = Math.floor(this.video.duration) || this.defaultTime;
        },
        _onTimeUpdate: function(event) {
            var threshold = this._calculateTimeThreshold();
            var currentTime = event.currentTarget.currentTime;
            if (currentTime > threshold && !this.video.paused && !this.$container.hasClass('content-shown')) {
                this.$container.addClass('content-animated');
                var container = this.$container;
                setTimeout(function() {
                    container.addClass('content-shown');
                }, 100);
            }
        },
        _onEnded: function() {
            this.$replay.show();
            this.$skip.hide();
            this.$container.removeClass('content-animated');
        },
        _onPlay: function() {
            this.$replay.hide();
            this.$skip.show();
        },
        _onPressReplay: function() {
            if (this.video.paused) {
                this.video.currentTime = 0;
                this.video.play();
            }
        },
        _onPressSkip: function() {
            if (!this.video.paused) {
                this.$container.removeClass('content-animated');
                this.video.currentTime = this.duration;
                this.video.pause();
            }
        },
        _calculateTimeThreshold: function() {
            return this.duration * 0.85;
        }
    });
    $('.jumbotron.video').video();
})(jQuery);